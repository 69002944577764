import { RefObject, useLayoutEffect, useState } from 'react'

// TODO: Move this to the WCL as a general-purpose hook.
export const useHasOverflowingContent = (
  ref: RefObject<Element>,
  direction: 'horizontal' | 'vertical',
  callback?: (hasOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false)

  useLayoutEffect(() => {
    const { current } = ref

    if (!current) {
      return
    }

    const trigger = () => {
      const hasOverflow = checkHasOverflow()
      setIsOverflow(hasOverflow)
      if (callback) {
        callback(hasOverflow)
      }
    }

    const checkHasOverflow = () => {
      if (direction === 'horizontal') {
        return current.scrollWidth > current.clientWidth
      }
      return current.scrollHeight > current.clientHeight
    }

    trigger()
  }, [callback, ref])

  return isOverflow
}