import React from 'react'
import { PlaybookTaskProps, PlaybookTaskRenderProps, TaskType } from 'types/Playbook.types'

import './styles.css'

const PlaybookTask: React.FC<PlaybookTaskRenderProps> = ({
  title,
  description,
  tip,
  duration,
  taskType,
  nextTask
}) => {

  const isNextTaskDifferentType = nextTask && nextTask.taskType !== taskType

  return (
    <>
      <div data-testid='PlaybookTask'>
        {taskType === TaskType.Personal &&
          <div className={`Timeline_container ${isNextTaskDifferentType && 'Timeline_container___isEATaskNext'}`}>
            <div className='Timeline_personal_task'>
              <div className='PersonalTask_container'>
                <PlaybookTaskPersonal
                  title={title}
                  description={description}
                  duration={duration}
                  tip={tip}
                  taskType={TaskType.Personal} />
              </div>
              <div className='Timeline_personal_task_connector_alignment'>
                <div className='Timeline_personal_task_connector'></div>
                <div className='Timeline_personal_task_checkpoint'></div>
              </div>
            </div>
            <div></div>
          </div>
        }
        {taskType === TaskType.ExecutiveAssistant &&
          <div className={`Timeline_container ${isNextTaskDifferentType && 'Timeline_container___isPersonalTaskNext'}`}>
            <div>
              <div className='Timeline_dashed_border'></div>
            </div>
            <div className='Timeline_ea_task'>
              <div className='Timeline_ea_task_connector_alignment'>
                <div className='Timeline_ea_task_checkpoint'></div>
                <div className='Timeline_ea_task_connector'></div>
              </div>
              <div className='EaTask_container'>
                <PlaybookTaskEa
                  title={title}
                  description={description}
                  tip={tip}
                  taskType={TaskType.ExecutiveAssistant} />
              </div>
            </div>
          </div>
        }
      </div>
      <div className='Timeline_mobile_dashed_border_container'>
        <div className='Timeline_mobile_dashed_border'></div>
      </div>
    </>
  )
}

const PlaybookTaskEa: React.FC<PlaybookTaskProps> = ({
  title,
  description,
  tip
}) => {
  return (
    <div className='EaTask'>
      <div className='EaTask_header'>
        <h2 className='EaTask_title'>{title}</h2>
      </div>
      <div className='EaTask_description'>{description}</div>
      {tip && <div className='EaTask_sub_description'>
        <span>{tip}</span>
      </div>}
    </div>
  )
}

const PlaybookTaskPersonal: React.FC<PlaybookTaskProps> = ({
  title,
  description,
  duration,
  tip
}) => {
  return (
    <div className='PersonalTask'>
      <div className='PersonalTask_header'>
        <h2 className='PersonalTask_title'>{title}</h2>
        {duration &&
          <div className='PersonalTask_duration'>
            <h2 className='PersonalTask_durationText'>{duration}</h2>
          </div>
        }
      </div>
      <div className='PersonalTask_description'>{description}</div>
      {tip && <div className='PersonalTask_sub_description'>
        <div>{tip}</div>
      </div>}
    </div>
  )
}

export default PlaybookTask
