import { useQuery } from '@tanstack/react-query'
import { getPlaybooks, getTags } from 'adapters/Contentful'
import { PlaybookProps } from 'types/Playbook.types'
import { createPlaybookProps } from 'utils/Playbooks'

export const PLAYBOOKS_QUERY_KEY = 'playbooks' as const

// TODO: Convert into a PlaybookResults factory hook when we start querying per section instead of all.
export const usePlaybookResults = (query: object) => {
  const { data: playbookResults, isLoading, isError, error, refetch } = useQuery({
    queryKey: [PLAYBOOKS_QUERY_KEY, query],
    queryFn: async () => {
      const tags = await getTags()
      const playbookEntries = await getPlaybooks(query)
      const playbooks: Array<PlaybookProps> = playbookEntries.map(
        (playbook) => createPlaybookProps(playbook, tags)
      )
      return playbooks
    },
    refetchOnWindowFocus: false,
    retry: 0
  })

  return {
    playbookResults,
    isLoading,
    isError,
    error,
    refetch
  }
}