import React, { MouseEventHandler } from 'react'
import * as Contentful from 'contentful'

import { TypeClientTask, TypeEaTask, TypePerson } from './Contentful.types'

export type PlaybookTrackedProps = Pick<PlaybookProps, 'slug' | 'title'>

export enum PlaybookPillar {
  Foundations = 'foundations',
  Essentials = 'essentials',
  Career = 'career',
  Lifestyle = 'lifestyle',
  Health = 'health',
  Fulfillment = 'fulfillment',
  Relationships = 'relationships'
}

export interface PlaybookProps {
  author?: TypePerson<Contentful.ChainModifiers, string>[]
  collection?: Array<string>
  nextPlaybook?: PlaybookProps
  overview: string
  pillar: PlaybookPillar
  result: string
  slug?: string
  tags: Contentful.Tag[]
  tasks: PlaybookTaskProps[]
  templates?: string[]
  title: string
}

export interface PlaybookComponentProps {
  playbook: PlaybookProps
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

export type PlaybookTaskProps = {
  title: string
  description: React.ReactNode
  tip?: React.ReactNode
  duration?: string
  taskType: TaskType
}

export interface PlaybookSummaryProps {
  author?: TypePerson<Contentful.ChainModifiers, string>[]
  overview: string
  pillar: string
  result: string
  slug: string
  tags: Array<Contentful.Tag>
  templates?: string[]
  title: string
}

export interface PlaybookContentProps {
  nextPlaybook?: PlaybookProps
  slug: string
  tasks: Array<PlaybookTaskProps>
  title: string
}

export type PlaybookTaskRenderProps = PlaybookTaskProps & {
  nextTask: PlaybookTaskProps | undefined
}

export enum TaskType {
  Personal = 'personal',
  ExecutiveAssistant = 'executiveAssistant'
}

export type PlaybookTask = TypeClientTask<Contentful.ChainModifiers, string> |
  TypeEaTask<Contentful.ChainModifiers, string>