import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Analytics } from 'adapters/Analytics'
import { usePlaybook } from 'hooks/usePlaybook'
import ErrorPage from 'pages/Error'
import LibraryLoadingSpinner from 'pages/Library/components/LibraryLoadingSpinner'
import { KnownError } from 'types/Error.types'
import { PlaybookPillar } from 'types/Playbook.types'

import PlaybookContent from './components/PlaybookContent'
import PlaybookSummary from './components/PlaybookSummary'

import './styles.css'

const Playbook = (): JSX.Element => {
  const { pillar, slug } = useParams()
  // We assert the :pillar URL parameter as a valid PlaybookPillar since we can validate the pillar without needing an API request.
  const pillarUrlParam = pillar as PlaybookPillar

  // Show Not Found error page when pillar and slug are invalid.
  if (!pillar || !slug || !Object.values(PlaybookPillar).includes(pillarUrlParam)) {
    return <ErrorPage error={KnownError.NotFound} logoShown={true} />
  }

  const { playbook, error, isError } = usePlaybook(pillarUrlParam, slug)

  useEffect(() => {
    // Track when a playbook is viewed.
    if (playbook) {
      document.title = `${playbook.title} | Athena Playbook Library`
      playbook && Analytics.track('Playbook viewed', {
        slug,
        title: playbook.title
      })
    }
  }, [playbook])

  useEffect(() => {
    // Track when there are errors.
    if (error) {
      // TODO: Remove this when Sentry is added.
      Analytics.track('Playbook unhandled error', { error })
    }
  }, [error])

  if (isError) {
    return (
      <ErrorPage error={error} />
    )
  }

  if (!playbook) {
    return <LibraryLoadingSpinner />
  }

  const {
    author,
    nextPlaybook,
    overview,
    result,
    tags,
    tasks,
    templates,
    title
  } = playbook

  return (
    <section className='Playbook'>
      <div className='Playbook_summary'>
        <PlaybookSummary
          author={author}
          overview={overview}
          pillar={pillar}
          result={result}
          slug={slug}
          tags={tags}
          templates={templates}
          title={title} />
      </div>
      <div className='Playbook_content'>
        <PlaybookContent
          slug={slug}
          title={title}
          tasks={tasks}
          nextPlaybook={nextPlaybook} />
      </div>
    </section>
  )
}
export default Playbook
