import { useQuery } from '@tanstack/react-query'
import { getPlaybook, getPlaybookById, getTags } from 'adapters/Contentful'
import { PlaybookPillar } from 'types/Playbook.types'
import { createPlaybookProps } from 'utils/Playbooks'

export const PLAYBOOK_QUERY_KEY = 'playbook' as const

export const usePlaybook = (pillar: PlaybookPillar, slug: string) => {
  const { data: playbook, isLoading, isError, error, refetch } = useQuery({
    queryKey: [PLAYBOOK_QUERY_KEY, pillar, slug],
    queryFn: async () => {
      const tags = await getTags()
      const playbookEntry = await getPlaybook(pillar, slug)
      const playbookProps = createPlaybookProps(playbookEntry, tags)
      const nextPlaybookLink = playbookEntry.fields.nextPlaybook
      if (nextPlaybookLink && nextPlaybookLink.sys) {
        // @ts-expect-error Ignoring the TS error on `id` because TS strangely thinks it does not exist in `sys`.
        const playbookSysId = nextPlaybookLink.sys.id
        const nextPlaybookEntry = await getPlaybookById(playbookSysId)
        const nextPlaybookProps = createPlaybookProps(nextPlaybookEntry, tags)
        playbookProps.nextPlaybook = nextPlaybookProps
      }
      return playbookProps
    },
    refetchOnWindowFocus: false,
    retry: 0
  })

  return {
    playbook,
    isLoading,
    isError,
    error,
    refetch
  }
}