import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@athena-labs/web-component-library'
import { Path } from 'AppRoutes'
import { DEFAULT_SHARE_TEXT } from 'constants/share'
import useWebShare from 'hooks/useWebShare'
import { PlaybookSummaryProps, PlaybookTrackedProps } from 'types/Playbook.types'

import AppLogo from 'components/AppLogo'
import AuthorLinks from 'components/AuthorLinks'
import ShareModal from 'components/ShareModal'
import TagLinks from 'components/TagLinks'

import './styles.css'

const PlaybookSummary: React.FC<PlaybookSummaryProps> = ({
  author,
  overview,
  pillar,
  result,
  slug,
  tags,
  templates,
  title
}) => {
  const [shareModal, setShareModal] = useState(false)
  const navigate = useNavigate()
  const { share } = useWebShare({ fallback: useCallback(() => setShareModal(true), []) })

  // TODO: Maintain share data and tracked data in a Store for client state management.
  const shareData: ShareData = {
    text: DEFAULT_SHARE_TEXT,
    title,
    url: window.location.href
  }

  const trackedData: PlaybookTrackedProps = {
    title,
    slug
  }

  return (
    <aside className='PlaybookSummary ___bgGradient'>
      {shareModal && (
        <ShareModal
          trackedData={trackedData}
          shareData={shareData}
          onClose={() => setShareModal(false)} />
      )}
      <div className='PlaybookSummary_container'>
        <AppLogo />
        <div className='PlaybookSummary_viewLibraryButton'>
          <Button
            icon='book-closed'
            variant='rounded'
            onClick={() => navigate(Path.Root)}
          >
            View Library
          </Button>
        </div>
        <div className='PlaybookSummary_heading'>
          <a className='PlaybookSummary_pillar' href={`/${pillar}`}>{pillar}</a>
          <h1 className='PlaybookSummary_title'>{title}</h1>
          <div className='PlaybookSummary_metadata'>
            <TagLinks tags={tags} />
            {author &&
              <div className='PlaybookSummary_authors'>
                <AuthorLinks authors={author} />
              </div>}
          </div>
        </div>
        <div className='PlaybookSummary_details'>
          <h6 className='PlaybookSummary_detailsTitle'>Overview</h6>
          <p className='PlaybookSummary_detailsDescription'>{overview}</p>
          <h6 className='PlaybookSummary_detailsTitle'>Result</h6>
          <p className='PlaybookSummary_detailsDescription'>{result}</p>
          {templates && (
            <>
              <h6 className='PlaybookSummary_detailsTitle'>Templates</h6>
              <ul className='PlaybookSummary_templates'>
                {templates.map(template => (
                  <li key={template}>
                    <a
                      className='PlaybookSummary_templateLink'
                      href={template}
                      target='_blank'
                      rel='noreferrer'>
                      {template}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className='PlaybookSummary_shareSection ___isDesktopBlock'>
          <Button
            icon='share-one'
            variant='rounded'
            onClick={() => share(shareData, trackedData)}
          >
            Share Playbook
          </Button>
        </div>
      </div>
    </aside>
  )
}
export default PlaybookSummary
