import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import { lookupTagsFromMetadata } from 'adapters/Contentful'
import { ChainModifiers, Tag } from 'contentful'
import { TypeClientTaskFields, TypePerson, TypePlaybook } from 'types/Contentful.types'
import { PlaybookPillar, PlaybookProps, PlaybookTask, TaskType } from 'types/Playbook.types'

// TODO: Minimize the transformation of Playbook Contentful content-types into Playbook Props
// similar to how we're doing it for Task Groups and Tasks in the Portal.
// Right now, we assert a lot of type in the transformation which can lead to maintenance hell.
export const createPlaybookProps = (
  playbook: TypePlaybook<ChainModifiers, string>,
  tags: Tag[]
): PlaybookProps => {
  const playbookTasks = playbook.fields.tasks as PlaybookTask[]
  // We assert the field types here since Contentful has incompatible types for string and reference fields.
  return {
    author: playbook.fields.ref_author as TypePerson<ChainModifiers, string>[],
    collection: playbook.fields.collection as string[],
    overview: playbook.fields.overview.toString(),
    pillar: playbook.fields.pillar as PlaybookPillar,
    result: playbook.fields.result.toString(),
    slug: playbook.fields.slug?.toString(),
    tags: lookupTagsFromMetadata(tags, playbook.metadata.tags),
    templates: playbook.fields.templates as string[],
    title: playbook.fields.title.toString(),
    tasks: playbookTasks.map((task) => {
      // We first assert to unknown to remove the Contentful's native typing on string fields: `{ [x: string]: string | undefined; }`
      // We then assert the fields as TypeClientTaskFields so that it reads the optional `duration` property.
      const taskFields = task.fields as unknown as TypeClientTaskFields
      return {
        title: task.fields.title.toString(),
        description: documentToReactComponents(task.fields.description as Document),
        tip: documentToReactComponents(task.fields.tip as Document),
        duration: taskFields.duration?.toString(),
        taskType: task.sys.contentType.sys.id === 'eaTask' ? TaskType.ExecutiveAssistant : TaskType.Personal
      }
    })
  }
}