import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IconLegacy } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { createPlaybooksByPillarQuery } from 'adapters/Contentful'
import { usePlaybookResults } from 'hooks/usePlaybookResults'
import ErrorPage from 'pages/Error'
import { KnownError } from 'types/Error.types'
import { PlaybookPillar } from 'types/Playbook.types'
import { PillarLibraryRouteParams } from 'types/Route.types'

import LibraryCard from '../components/Card'
import LibraryLoadingSpinner from '../components/LibraryLoadingSpinner'
import PillarNavigation from '../Home/components/PillarNavigation'

import './styles.css'

const PillarLibrary: React.FC = () => {
  // We assert the Pillar Library route params type to override react-router-dom's typing where route parameters can be undefined.
  // See here: https://stackoverflow.com/questions/59085911/required-url-param-on-react-router-v5-with-typescript-can-be-undefined#comment130119757_66697883
  const { pillar } = useParams() as PillarLibraryRouteParams
  const pillarQuery = createPlaybooksByPillarQuery(pillar)
  const { playbookResults, error, isError } = usePlaybookResults(pillarQuery)

  useEffect(() => {
    Analytics.track('Playbook pillar', {
      pillar
    })
  }, [pillar])

  if (!Object.values(PlaybookPillar).includes(pillar)) {
    return <ErrorPage error={KnownError.NotFound} />
  }

  if (isError) {
    return <ErrorPage error={error} />
  }

  if (!playbookResults) {
    return <LibraryLoadingSpinner />
  }

  return (
    <section className='PillarLibrary'>
      <PillarNavigation />
      <div className='PillarLibrary_content ___content'>
        <div className='___section'>
          <div className='PillarLibrary_heading ___sectionHeading'>
            <div className='LibraryHomeSection_icon'>
              <IconLegacy type={`pillar-${pillar as PlaybookPillar}`} color='secondary' />
            </div>
            <h2 className='LibraryHomeSection_headingTitle'>
              {pillar}
            </h2>
          </div>
        </div>
        <div className='___section'>
          <div className='___gridWrapper'>
            <div className='___grid'>
              {playbookResults && playbookResults.map((playbook) => (
                <LibraryCard key={playbook.title} {...playbook} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PillarLibrary