import React from 'react'
import hourglassSvg from 'assets/hourglass.svg'
import { AnimatePresence, motion } from 'framer-motion'

import './styles.css'

// TODO: Move this to WCL
const LoadingSpinner: React.FC = () => (
  <AnimatePresence>
    <motion.div
      className='LoadingSpinner'
      key='LoadingSpinner'
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className='LoadingSpinner_animationWrapper'>
        <img className='LoadingSpinner_animationIcon' src={hourglassSvg} alt='Loading Icon' />
      </div>
    </motion.div>
  </AnimatePresence>
)

export default LoadingSpinner