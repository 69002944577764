import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Context, Icon, IconType } from '@athena-labs/web-component-library'
import { Path } from 'AppRoutes'
import { DEFAULT_SHARE_TEXT } from 'constants/share'
import useWebShare from 'hooks/useWebShare'
import { PlaybookContentProps, PlaybookTrackedProps } from 'types/Playbook.types'

import ShareModal from 'components/ShareModal'

import PlaybookContentHeader from '../PlaybookContentHeader'
import PlaybookTask from '../PlaybookTask'

import './styles.css'

const PlaybookContent: React.FC<PlaybookContentProps> = ({ nextPlaybook, slug, tasks, title }) => {
  const navigate = useNavigate()
  const [shareModal, setShareModal] = useState(false)
  const { share } = useWebShare({ fallback: useCallback(() => setShareModal(!shareModal), []) })

  // TODO: Maintain share data and tracked data in a Store for client state management.
  const shareData = {
    text: DEFAULT_SHARE_TEXT,
    title,
    url: window.location.href
  }

  const trackedData: PlaybookTrackedProps = {
    title,
    slug
  }

  return (
    <section className='PlaybookContent'>
      {shareModal && (
        <ShareModal
          trackedData={trackedData}
          shareData={shareData}
          onClose={() => setShareModal(false)} />
      )}
      <div className='PlaybookContent_wrapper'>
        <PlaybookContentHeader />
        {tasks.map((task, i) =>
          <PlaybookTask
            key={i}
            {...task}
            nextTask={tasks[i + 1]}
          />
        )}
        <div className='PlaybookContent_dashedLineExtenderWrapper'>
          <div className='Timeline_dashed_border'></div>
        </div>
        <div className='PlaybookContent_buttons'>
          <div className='PlaybookContent_button'>
            <Button
              icon='book-closed'
              variant='rounded'
              onClick={() => navigate(Path.Root)}
            >
              View more playbooks
            </Button>
          </div>
          <div className='PlaybookContent_button PlaybookContent_button___share'>
            <Button
              icon='share-one'
              variant='rounded'
              onClick={() => share(shareData)}
            >
              Share Playbook
            </Button>
          </div>
        </div>
      </div>
      <div className='PlaybookContent_dashedLineExtenderWrapper'>
        <div className='Timeline_dashed_border'></div>
      </div>
      {nextPlaybook && (
        <div className='PlaybookContent_nextPlaybook'>
          <h3 className='PlaybookContent_nextPlaybookHeadingText'>Up Next</h3>
          <Link className='PlaybookContent_nextPlaybookLink' to={`/${nextPlaybook.pillar}/${nextPlaybook.slug}`}>
            <aside className='PlaybookContent_nextPlaybookCard'>
              <div className='PlaybookContent_nextPlaybookDetails'>
                <h4 className='PlaybookContent_nextPlaybookTitle'>{nextPlaybook.title}</h4>
                <p className='PlaybookContent_nextPlaybookOverview'>{nextPlaybook.overview}</p>
              </div>
              <div className='PlaybookContent_openPlaybookButton ___isDesktopBlock'>
                <Button variant='ghost'>
                  <div className='PlaybookContent_openPlaybookButtonContent'>
                    <span>Open Playbook</span>
                    <figure className='PlaybookContent_openPlaybookButtonIcon'>
                      <Icon type={IconType.Redirect} context={Context.Active} />
                    </figure>
                  </div>
                </Button>
              </div>
            </aside>
          </Link>
        </div>
      )}
    </section>
  )
}
export default PlaybookContent