import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { IconLegacy } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { Path } from 'AppRoutes'
import { usePlaybookResults } from 'hooks/usePlaybookResults'
import useQueryParams from 'hooks/useQueryParams'
import ErrorPage from 'pages/Error'

import LibraryCard from '../components/Card'
import LibraryLoadingSpinner from '../components/LibraryLoadingSpinner'

const Search = () => {
  const queryParams = useQueryParams()
  const searchParam = queryParams.get('query')
  // TODO: Search the keyword by title, by author, and by tags instead of all.
  const { playbookResults, error, isError } = usePlaybookResults({
    query: ''
  })

  useEffect(() => {
    if (searchParam) {
      Analytics.track('Playbook search', {
        query: searchParam
      })
    }
  }, [])

  const sanitizeString = (string: string) => {
    return string.toLowerCase().trim()
  }

  const highlightWord = (target: string, word?: string): string | JSX.Element => {
    if (!word) {
      return target
    }
    const result = []
    let currentIndex = 0
    let startIndex = target.toLowerCase().indexOf(word.toLowerCase())
    // iterate every instance of the word
    while (startIndex !== -1) {
      result.push(target.slice(currentIndex, startIndex))
      result.push(<mark className='bg-accent-blue rounded-sm' key={startIndex}>{target.slice(startIndex, startIndex + word.length)}</mark>)
      currentIndex = startIndex + word.length
      startIndex = target.indexOf(word, currentIndex)
    }
    result.push(target.slice(currentIndex))
    return <>{result}</>
  }

  if (!searchParam) {
    return <Navigate to={Path.Root} />
  }

  const searchKeyword = searchParam.toLowerCase().trim()

  const byTitle = playbookResults ?
    playbookResults.filter((playbook) => {
      return sanitizeString(playbook.title).indexOf(searchKeyword) > -1
    }).map((playbook) => ({
      ...playbook,
      title: highlightWord(playbook.title, searchKeyword)
    })) : []

  const byTag = playbookResults ?
    playbookResults.filter((playbook) => {
      return playbook.tags?.some((tag) => {
        return sanitizeString(tag.name) === searchKeyword
      })
    }).map((playbook) => ({
      ...playbook,
      tags: playbook.tags.map((tag) => {
        if (sanitizeString(tag.name) === searchKeyword) {
          return { ...tag, active: true }
        }
        return tag
      })
    })) : []

  const byAuthor = playbookResults ?
    playbookResults.filter((playbook) => {
      return playbook.author?.some((author) => {
        const authorName = author.fields.name as string
        return sanitizeString(authorName).indexOf(searchKeyword) !== -1
      })
    }).map(playbook => ({
      ...playbook,
      author: playbook.author ? playbook.author.map((author) => {
        const authorName = author.fields.name as string
        if (sanitizeString(authorName).indexOf(searchKeyword) !== -1) {
          return { ...author, active: true }
        }
        return author
      }) : undefined
    })) : []

  if (isError) {
    return <ErrorPage error={error} />
  }

  if (!playbookResults) {
    return <LibraryLoadingSpinner />
  }

  return (
    <section className='LibrarySearch'>
      <div className='LibrarySearch_content ___content'>
        <div className='___section'>
          <div className='___sectionHeading'>
            <div className='Library_searchIcon'>
              <IconLegacy type='search' color='secondary' />
            </div>
            <h2>
              {`"${searchParam || ''}"`}
            </h2>
          </div>
        </div>
        <div className='___section'>
          <div className='___gridWrapper'>
            <div className='___grid'>
              {byTitle.map((playbook, index) =>
                <LibraryCard key={index} {...playbook} />
              )}
              {byTag.map((playbook, index) =>
                <LibraryCard key={index} {...playbook} />)}
              {byAuthor.map((playbook, index) =>
                <LibraryCard key={index} {...playbook} />)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Search