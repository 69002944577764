import { useMediaQuery } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { PlaybookTrackedProps } from 'types/Playbook.types'


type UseWebShareParam = {
  fallback: () => void
  callback?: () => void
}

const useWebShare = (params: UseWebShareParam) => {
  const { fallback, callback } = params
  const { isDesktop } = useMediaQuery()

  // We pass in an optional `trackedData` argument so we can send data to be tracked to our Analytics provider.
  // TODO: Update trackedData type to a more general TrackedData type.
  const share = async (data: ShareData, trackedData?: PlaybookTrackedProps) => {
    if (!isDesktop && navigator.share && navigator.canShare(data)) {
      void navigator.share(data)
      if (trackedData) {
        Analytics.track('Shared on mobile', trackedData)
      }
      callback && await callback()
    } else {
      await fallback()
    }
  }

  return {
    share
  }

}

export default useWebShare