import { PlaybookTrackedProps } from './Playbook.types'

export enum ShareTarget {
  Email = 'Email',
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter'
}

export type ShareComponentProps = {
  trackedData: PlaybookTrackedProps
  shareData: ShareData
}

export type ShareButtonProps = ShareComponentProps & {
  target: ShareTarget
}

export type ShareModalProps = ShareComponentProps & {
  onClose: () => void
}