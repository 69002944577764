import { useQuery } from '@tanstack/react-query'
import { getAuthor } from 'adapters/Contentful'

export const AUTHOR_QUERY_KEY = 'author' as const

export const useAuthor = (authorSlug: string) => {
  const { data: author, isLoading, isError, error, refetch } = useQuery({
    queryKey: [AUTHOR_QUERY_KEY, authorSlug],
    queryFn: async () => await getAuthor(authorSlug),
    refetchOnWindowFocus: false,
    retry: 0
  })

  return {
    author,
    isLoading,
    isError,
    error,
    refetch
  }
}