import React from 'react'

import LoadingSpinner from 'components/LoadingSpinner'

import './styles.css'

// TODO: Move this to WCL
const LibraryLoadingSpinner: React.FC = () => (
  <div className='LibraryLoadingSpinner'>
    <LoadingSpinner />
  </div>
)

export default LibraryLoadingSpinner