import React from 'react'
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share'
import { Button } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { DEFAULT_SHARE_TEXT } from 'constants/share'
import { ShareButtonProps, ShareTarget } from 'types/Share.types'

const ShareButton: React.FC<ShareButtonProps> = ({ shareData, target, trackedData }) => {
  // the playbook details
  const trackSharing = () => {
    Analytics.track('Playbook shared', {
      target,
      ...trackedData
    })
  }

  const ReactShareMap = {
    Email: {
      component: EmailShareButton,
      icon: 'mail-one'
    },
    LinkedIn: {
      component: LinkedinShareButton,
      icon: 'linkedin'
    },
    Twitter: {
      component: TwitterShareButton,
      icon: 'twitter'
    }
  }

  const ReactShareComponent = ReactShareMap[target]

  const getReactShareComponentProps = () => {
    switch (target) {
      case ShareTarget.Email:
        return {
          beforeOnClick: trackSharing,
          subject: shareData.title,
          body: DEFAULT_SHARE_TEXT
        }
      case ShareTarget.LinkedIn:
        return {
          title: shareData.title,
          summary: DEFAULT_SHARE_TEXT,
          onShareWindowClose: trackSharing
        }
      case ShareTarget.Twitter:
        return {
          title: `${DEFAULT_SHARE_TEXT} ${shareData.title}`,
          onShareWindowClose: trackSharing
        }
    }
  }

  const reactShareComponentProps = getReactShareComponentProps()

  return (
    <ReactShareComponent.component
      url={location.href}
      // Needed to prevent double sharing, see https://github.com/nygardk/react-share/issues/153#issuecomment-739480260
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={() => { }}
      openShareDialogOnClick={true}
      {...reactShareComponentProps}>
      <Button icon={ReactShareMap[target].icon} variant='rounded'>
        {target}
      </Button>
    </ReactShareComponent.component>
  )
}

export default ShareButton