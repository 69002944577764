import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IconLegacy, useMediaQuery } from '@athena-labs/web-component-library'
import { Path } from 'AppRoutes'
import { PILLARS } from 'constants/pillars'

import './styles.css'

const PillarNavigation: React.FC = () => {

  const { isDesktop } = useMediaQuery()
  const [filterButtonsShown, setFilterButtonsShown] = useState<boolean>(false)

  const { pillar: selectedPillar } = useParams()
  const pillarDescription = PILLARS.find((pillar) => pillar.name === selectedPillar)?.description

  return (
    <div className='PillarNavigation_controls'>
      <button
        className='PillarNavigation_filterButtonToggle ___bgGradient'
        onClick={() => setFilterButtonsShown((isFilterButtonsShown) => !isFilterButtonsShown)}>
        <h3 className='PillarNavigation_filterButtonText'>
          {selectedPillar !== undefined ? selectedPillar : 'All Playbooks'}
          {pillarDescription && (
            <span className='PillarNavigation_filterButtonTags'>{pillarDescription}</span>
          )}
        </h3>
        <figure>
          <IconLegacy type={filterButtonsShown ? 'chevron-up' : 'chevron-down'} color='secondary' />
        </figure>
      </button>
      <div
        className={[
          'PillarNavigation_pillarFilters',
          (!filterButtonsShown && !isDesktop) ? 'hidden' : ''
        ].join(' ')}>
        <Link
          className={[
            'PillarNavigation_filterButton PillarNavigation_filterButton___all ___bgGradient',
            selectedPillar === undefined && 'PillarNavigation_filterButton___active'
          ].join(' ')}
          to={Path.Root}>
          <span className='PillarNavigation_filterButtonText'>All Playbooks</span>
        </Link>
        {PILLARS.map((pillar) => (
          <Link
            key={pillar.name}
            className={[
              'PillarNavigation_filterButton ___bgGradient',
              selectedPillar === pillar.name && 'PillarNavigation_filterButton___active'
            ].join(' ')}
            to={`/${pillar.name}`}>
            <span className='PillarNavigation_filterButtonText'>{pillar.name}</span>
            <span className='PillarNavigation_filterButtonTags'>{pillar.description}</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default PillarNavigation