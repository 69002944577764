import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Analytics } from 'adapters/Analytics'
import { useAuthor } from 'hooks/useAuthor'
import { usePlaybookResults } from 'hooks/usePlaybookResults'
import ErrorPage from 'pages/Error'
import { AuthorLibraryRouteParams } from 'types/Route.types'

import LibraryCard from '../components/Card'
import LibraryLoadingSpinner from '../components/LibraryLoadingSpinner'

import './styles.css'

const AuthorLibrary: React.FC = () => {
  const { authorSlug } = useParams() as AuthorLibraryRouteParams
  const { author, error: authorError, isError: hasAuthorError } = useAuthor(authorSlug)
  // TODO: Specifically query to get the playbooks by author once we have separate fields for client and partner authors.
  const {
    playbookResults,
    error: playbookResultsError,
    isError: hasPlaybookResultsError
  } = usePlaybookResults({
    query: ''
  })

  useEffect(() => {
    if (authorSlug) {
      Analytics.track('Playbook author', {
        author: authorSlug
      })
    }
  }, [])

  const results = useMemo(() => {
    return playbookResults?.filter((playbook) =>
      playbook.author?.some(author => author.fields.slug === authorSlug)
    )
  }, [playbookResults, authorSlug])

  if (hasAuthorError) {
    return <ErrorPage error={authorError} />
  }

  if (hasPlaybookResultsError) {
    return <ErrorPage error={playbookResultsError} />
  }

  if (!playbookResults || !author) {
    return <LibraryLoadingSpinner />
  }

  return (
    <section className='AuthorLibrary'>
      <div className='AuthorLibrary_content ___content'>
        <div className='___section'>
          <div className='___sectionHeading'>
            <h2>
              Playbooks by {author.fields.name}
            </h2>
          </div>
        </div>
        <div className='___section'>
          <div className='___gridWrapper'>
            <div className='___grid'>
              {results && results.map((playbook) => (
                <LibraryCard key={playbook.title} {...playbook} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AuthorLibrary