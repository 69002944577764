import React from 'react'
import { Link } from 'react-router-dom'
import { AuthorLinksProps } from 'types/Library.types'

const AuthorLinks: React.FC<AuthorLinksProps> = ({ authors = [] }) => {
  const authorLinks = authors.map((author) => (
    <Link
      key={author.sys.id}
      className={
        ['AuthorLink',
          author.active ? 'bg-accent-blue text-primary-black' : '']
          .join(' ')
      }
      to={`/authors/${author.fields.slug}`}
      onClick={(event) => event.stopPropagation()}>
      {author.fields.name.toString()}
    </Link>
  ))
  const authorLinksWithSeparator = authorLinks.flatMap((authorLink, index) =>
    authorLinks.length - 1 !== index ? // Check for the last item
      [authorLink, ' / '] :
      authorLink)

  return <>{authorLinksWithSeparator}</>
}

export default AuthorLinks