import React from 'react'
import { Link } from 'react-router-dom'
import { TagLinksProps } from 'types/Library.types'

import './styles.css'

const TagLinks: React.FC<TagLinksProps> = ({ tags, disabled }) => {


  return (
    <div className='TagLinks'>
      {tags.map((tag) => {
        if (disabled) {
          return (
            <span
              key={tag.sys.id}
              className={[
                'TagLinks_button',
                'TagLinks_button___disabled',
                tag.active && 'TagLinks_button___active'
              ].join(' ')}
            >
              {tag.name}
            </span>
          )
        }
        return (
          <Link
            key={tag.sys.id}
            to={`/tags/${tag.sys.id}`}
            onClick={(event) => event.stopPropagation()}
            className={[
              'TagLinks_button',
              tag.active && 'TagLinks_button___active'
            ].join(' ')}
          >
            {tag.name}
          </Link>
        )
      })}
    </div>
  )
}

export default TagLinks