import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PlaybookComponentProps } from 'types/Playbook.types'

import AuthorLinks from 'components/AuthorLinks'
import TagLinks from 'components/TagLinks'

import './styles.css'

const LibraryHomePreviewCard: React.FC<PlaybookComponentProps> = ({
  playbook,
  onMouseLeave
}) => {
  const navigate = useNavigate()

  const navigateToPlaybookPage = () => {
    navigate(`/${playbook.pillar}/${playbook.slug}`)
  }

  // Need to use div instead of Link to avoid the error of having anchor tags as a descendant of another anchor tag.
  return (
    <div
      role='button'
      className='LibraryHomePreviewCard'
      onClick={navigateToPlaybookPage}
      onMouseLeave={onMouseLeave}
    >
      <h2 className='LibraryHomePreviewCard_title'>{playbook.title}</h2>
      <small className='LibraryHomePreviewCard_overview'>{playbook.overview}</small>
      <TagLinks disabled={true} tags={playbook.tags} />
      {playbook.author && (
        <div className='LibraryHomePreviewCard_authors'>
          <AuthorLinks authors={playbook.author} />
        </div>
      )}
    </div>
  )
}

export default LibraryHomePreviewCard