// TODO: Move this to a shared JS SDK.
import { ChainModifiers, createClient, Entry, EntryCollection, Tag, TagLink } from 'contentful'
import { TypePersonSkeleton, TypePlaybookSkeleton, TypeTaskGroupSkeleton, TypeTaskSkeleton } from 'types/Contentful.types'
import { KnownError } from 'types/Error.types'
import { PlaybookPillar } from 'types/Playbook.types'

/**
 * Contentful API Client
 * The first import of this adapter file creates a singleton instance of the API client.
 */
const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  space: process.env.REACT_APP_CONTENTFUL_SPACE
})

// #region Playbook and Author methods
export const createPlaybooksByPillarQuery = (pillar: PlaybookPillar) => {
  return {
    'fields.pillar': pillar
  }
}

// Contentful currently does not support searching on multiple-reference fields.
// See more: https://www.contentfulcommunity.com/t/searching-on-multiple-reference-fields/377/7
export const createPlaybooksByAuthorQuery = async (author: string) => {
  return {
    'fields.ref_author.sys.contentType.sys.id': 'author',
    'fields.ref_author.fields.name[all]': author
  }
}

export const createPlaybooksByTagQuery = (tagId: string) => {
  return {
    'metadata.tags.sys.id[all]': tagId
  }
}

export const getAuthor = async (slug: string) => {
  const entries = await client.getEntries<TypePersonSkeleton>({
    content_type: 'person',
    'fields.slug': slug
  })
  return entries.items[0] ?? null
}

export const getPlaybooks = async (query: object): Promise<
  Entry<TypePlaybookSkeleton, ChainModifiers, string>[]
> => {
  let entries: EntryCollection<TypePlaybookSkeleton>
  try {
    entries = await client.getEntries<TypePlaybookSkeleton>({
      content_type: 'playbook',
      // TODO: Remove this when we merchandise the 100 day collection in the playbooks library
      // Exclude playbooks in the '100 Day' collection
      'fields.collection[nin]': ['100 Day'],
      ...query
    })
  } catch (error: unknown) {
    // TODO: Handle and throw more specific errors.
    throw KnownError.Network
  }

  const playbooks = entries.items

  if (!playbooks) {
    throw KnownError.NotFound
  }

  return playbooks
}

export const getPlaybook = async (pillar: PlaybookPillar, slug: string) => {
  let entries: EntryCollection<TypePlaybookSkeleton>
  try {
    entries = await client.getEntries<TypePlaybookSkeleton>({
      content_type: 'playbook',
      'fields.pillar': pillar,
      'fields.slug': slug
    })
  } catch (error: unknown) {
    throw new Error(KnownError.Network)
  }

  const playbook = entries.items[0]
  if (!playbook) {
    throw new Error(KnownError.NotFound)
  }
  return playbook
}

export const getPlaybookById = async (id: string) => {
  let playbookEntry: Entry<TypePlaybookSkeleton>
  try {
    playbookEntry = await client.getEntry<TypePlaybookSkeleton>(id)
  } catch (error: unknown) {
    throw new Error(KnownError.Network)
  }

  if (!playbookEntry) {
    throw new Error(KnownError.NotFound)
  }
  return playbookEntry
}

export const searchPlaybooks = async (search: string) => {
  return await getPlaybooks({
    'query': search
  })
}
// #endregion

// #region Tag methods.
export const getTags = async () => {
  // Get tags from API since metadata.tags just has the IDs
  const tagsResponse = await client.getTags()
  return tagsResponse.items
}

export const lookupTagsFromMetadata = (tags: Array<Tag>, tagMetadata: { sys: TagLink }[]) => {
  const foundTags = tagMetadata.map((tag) => tags?.find((t) => t.sys.id === tag.sys.id))

  // Remove any tags that weren't found
  const validTags = foundTags.filter((t) => t !== undefined) as Array<Tag>

  // Just return the second part of the tag
  return validTags.map((t) => ({ ...t, name: t.name.split(':')[1] }))
}
// #endregion

// #region Task methods

export const getTasks = async (query: object): Promise<
  Entry<TypeTaskSkeleton, ChainModifiers, string>[]
> => {
  let entries: EntryCollection<TypeTaskSkeleton>
  try {
    entries = await client.getEntries<TypeTaskSkeleton>({
      content_type: 'task',
      ...query
    })
  } catch (error: unknown) {
    // TODO: Handle and throw more specific errors.
    throw KnownError.Network
  }

  const tasks = entries.items
  if (!tasks) {
    throw KnownError.NotFound
  }

  return tasks
}

export const getTaskGroups = async (query: object): Promise<
  Entry<TypeTaskGroupSkeleton, ChainModifiers, string>[]
> => {
  let entries: EntryCollection<TypeTaskGroupSkeleton>
  try {
    entries = await client.getEntries<TypeTaskGroupSkeleton>({
      content_type: 'taskGroup',
      ...query
    })
  } catch (error: unknown) {
    // TODO: Handle and throw more specific errors.
    throw KnownError.Network
  }

  const tasks = entries.items
  if (!tasks) {
    throw KnownError.NotFound
  }

  return tasks
}
// #endregion
export default client