import React, { useEffect, useMemo } from 'react'
import { Analytics } from 'adapters/Analytics'
import { PILLARS } from 'constants/pillars'
import { usePlaybookResults } from 'hooks/usePlaybookResults'
import ErrorPage from 'pages/Error'
import { PlaybookPillar } from 'types/Playbook.types'

import LibraryLoadingSpinner from '../components/LibraryLoadingSpinner'

import PillarNavigation from './components/PillarNavigation'
import LibraryHomeSection from './components/Section'

import './styles.css'

const Home: React.FC = () => {
  const { playbookResults, error, isError } = usePlaybookResults({
    query: ''
  })

  useEffect(() => {
    Analytics.track('Playbook homepage')
  }, [])

  const featuredPlaybooks = useMemo(() => {
    if (!playbookResults) {
      return []
    }
    return playbookResults.filter(
      (playbook) => Boolean(playbook.collection?.includes('Featured'))
    )
  }, [playbookResults])

  const getPlaybooksByPillar = (pillar: PlaybookPillar) => {
    if (!playbookResults) {
      return []
    }

    return [...playbookResults.filter(
      (playbook) => playbook.pillar === pillar
    )]
  }

  if (isError) {
    return (
      <ErrorPage error={error} />
    )
  }

  if (!playbookResults) {
    return <LibraryLoadingSpinner />
  }

  return (
    <section className='LibraryHome'>
      <PillarNavigation />
      <div className='LibraryHome_content'>
        <LibraryHomeSection
          featured={true}
          playbooks={featuredPlaybooks}
          title='Featured' />
        {/* TODO: Lazy load pillar sections as the user scrolls. */}
        {PILLARS.map((pillar) => {
          return (
            <LibraryHomeSection
              key={pillar.name}
              title={pillar.name}
              playbooks={getPlaybooksByPillar(pillar.name)}
              icon={pillar.icon} />
          )
        })}
      </div>
    </section>

  )
}

export default Home