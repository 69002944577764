import React from 'react'
import { Button } from '@athena-labs/web-component-library'

import Modal from 'components/Modal'

import { SupportModalProps } from './SupportModal.types'

import './styles.css'

const BUG_REPORTING_URL = 'https://form.asana.com/?k=ryOxXFCHA1orEDb2O238Ng&d=821830956080256'

const SupportModal: React.FC<SupportModalProps> = ({ onClose }) => {

  const onFaqClick = () => window.open('https://www.athena.com/faqs', '_blank')
  const onReportClick = () => window.open(BUG_REPORTING_URL, '_blank')
  const onSendMessageClick = () => {
    window.location.href = 'mailto:support@athenago.com'
  }

  return (
    <Modal
      heading='Get Help'
      body={(
        <div className='SupportModal_body'>
          <div className='SupportModal_actionBlock'>
            <p className='SupportModal_actionBlockSubHeading'>Have a question? Visit our frequently asked questions page.</p>
            <div className='SupportModal_actionBlockButton lg:w-1/2 lg:pr-6'>
              <Button onClick={onFaqClick} icon='redirect'>
                FAQS
              </Button>
            </div>
          </div>
          <div className='SupportModal_actions lg:mt-6'>
            <div className='SupportModal_actionBlock'>
              <h2 className='SupportModal_actionBlockHeading'>Spotted a bug?</h2>
              <p className='SupportModal_actionBlockSubHeading'>Send feedback directly to our technology team.</p>
              <div className='SupportModal_actionBlockButton'>
                <Button onClick={onReportClick} icon='redirect'>
                  Report a bug
                </Button>
              </div>
            </div>

            <div className='SupportModal_actionBlock'>
              <h2 className='SupportModal_actionBlockHeading'>Can&apos;t Find The Answer?</h2>
              <p className='SupportModal_actionBlockSubHeading'>Send a question to a member of our team.</p>
              <div className='SupportModal_actionBlockButton'>
                <Button onClick={onSendMessageClick} icon='arrow-right'>
                  Send Message
                </Button>
              </div>
            </div>
          </div>
        </div>)}
      onClose={onClose}
    />
  )
}

export default SupportModal
