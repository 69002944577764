import React from 'react'
import { IconLegacy } from '@athena-labs/web-component-library'
import { ErrorProps, ErrorUIProps, KnownError } from 'types/Error.types'

import AppLogo from 'components/AppLogo'

import './styles.css'

const Error: React.FC<ErrorProps> = ({
  error,
  logoShown
}) => {
  const renderKnownError = () => {
    switch (error) {
      case KnownError.Network:
        return <ErrorNetwork />
      case KnownError.NotFound:
        return <ErrorNotFound />
      default:
        return <ErrorUnknown />
    }
  }

  return (
    <section className='Error'>
      {logoShown && (
        <aside className='Error_logo'>
          <AppLogo />
        </aside>
      )}
      {renderKnownError()}
    </section>
  )
}

const ErrorUnknown: React.FC = () => {
  const supportEmailLink = `mailto:support@athenago.com?subject=Something went wrong in Athena Playbooks (${window.location.href})`
  return (
    <ErrorUI
      title={KnownError.Unknown}
      icon='alert-info'
      content={
        <p>
          Reach out to&nbsp;
          <a href={supportEmailLink}>support@athenago.com</a> if the problem persists.
        </p>
      }
    />
  )
}

const ErrorNetwork: React.FC = () => {
  return (
    <ErrorUI
      title={KnownError.Network}
      content='Error while fetching playbook'
    />
  )
}

const ErrorNotFound: React.FC = () => {
  const supportEmailLink = `mailto:support@athenago.com?subject=Page not found in Athena Playbooks (${window.location.href})`
  return (
    <ErrorUI
      title={KnownError.NotFound}
      icon='alert-info'
      content={
        <>
          <p>
            If you encounter this URL from one of our emails,
            please reach out to your onboarding manager or to our support team:
          </p>
          <a href={supportEmailLink}>
            support@athenago.com
          </a>
        </>
      }
    />
  )
}

const ErrorUI: React.FC<ErrorUIProps> = ({
  content,
  icon,
  title
}) => {
  return (
    <div className='ErrorUI'>
      {icon !== undefined && <figure className='ErrorUI_icon'>
        <IconLegacy type={icon} color='danger' />
      </figure>}
      <h1 className='ErrorUI_title'>{title}</h1>
      <div className='ErrorUI_content'>
        {content}
      </div>
    </div>
  )
}

ErrorUI.defaultProps = {
  icon: 'alert-error'
}

export default Error
