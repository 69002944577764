import { LibraryPillarSection } from 'types/Library.types'
import { PlaybookPillar } from 'types/Playbook.types'

export const PILLARS: Array<LibraryPillarSection> = [
  {
    icon: 'pillar-foundations',
    name: PlaybookPillar.Foundations,
    description: 'Inbox, Calendar, Travel'
  },
  {
    icon: 'pillar-essentials',
    name: PlaybookPillar.Essentials,
    description: 'Home, Image, Money'
  },
  {
    icon: 'pillar-career',
    name: PlaybookPillar.Career,
    description: 'Networking, Hiring & Team, Projects & Ops'
  },
  {
    icon: 'pillar-lifestyle',
    name: PlaybookPillar.Lifestyle,
    description: 'Food & Drink, Sports & Games, Arts & Leisure'
  },
  {
    icon: 'pillar-health',
    name: PlaybookPillar.Health,
    description: 'Wellness, Nutrition, Exercise'
  },
  {
    icon: 'pillar-fulfillment',
    name: PlaybookPillar.Fulfillment,
    description: 'Learning, Creativity, Productivity'
  },
  {
    icon: 'pillar-relationships',
    name: PlaybookPillar.Relationships,
    description: 'Partner, Family, Friends'
  }
]