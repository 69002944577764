import React from 'react'
import ReactDOM from 'react-dom'
import { IconLegacy } from '@athena-labs/web-component-library'
import { ModalProps } from 'types/Modal.types'

import './styles.css'

// TODO: Move this to WCL
// TODO: Change this component to render children (type React.ReactNode) rather than taking a body prop
const Modal: React.FC<ModalProps> = ({ onClose, heading, body }) => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement

  const renderModal = () => (
    <div className='Modal' onClick={onClose}>
      <div className='Modal_content' onClick={(e) => e.stopPropagation()}>
        {
          onClose && <div className='Modal_close'>
            <figure onClick={onClose}>
              <IconLegacy type='alert-error' color='secondary' />
            </figure>
          </div>
        }
        <div className='Modal_heading'>
          <h2 className='Modal_headingText'>{heading}</h2>
        </div>
        <div className='Modal_body'>
          {body}
        </div>
      </div>
    </div>
  )
  return (
    <>
      {ReactDOM.createPortal(<>{renderModal()}</>, modalRoot)}
    </>
  )
}

export default Modal