import { ReactNode } from 'react'
import { IconLegacyClass } from '@athena-labs/web-component-library'

export enum KnownError {
  Network = 'Network Error',
  NotFound = 'Not Found',
  Unknown = 'Something went wrong.'
}

export interface ErrorProps {
  error: KnownError | unknown
  logoShown?: boolean
}

export interface ErrorUIProps {
  content: string | ReactNode
  icon?: IconLegacyClass
  title: string
}