import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PlaybookComponentProps } from 'types/Playbook.types'

import TagLinks from 'components/TagLinks'

import './styles.css'

const LibraryHomeCard: React.FC<PlaybookComponentProps> = ({
  playbook,
  onMouseEnter
}) => {
  const navigate = useNavigate()

  const navigateToPlaybookPage = () => {
    navigate(`/${playbook.pillar}/${playbook.slug}`)
  }

  // Need to use div instead of Link to avoid the error of having anchor tags as a descendant of another anchor tag.
  return (
    <div
      role='button'
      className='LibraryHomeCard ___bgGradient'
      onClick={navigateToPlaybookPage}
      onMouseEnter={onMouseEnter}
    >
      <h2 className='LibraryHomeCard_title'>{playbook.title}</h2>
      <TagLinks disabled={true} tags={playbook.tags} />
    </div>
  )
}

export default LibraryHomeCard