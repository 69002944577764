import { hotjar } from 'react-hotjar'
import mixpanel, { Dict } from 'mixpanel-browser'

const { REACT_APP_MIXPANEL_ID, REACT_APP_HOTJAR_ID } = process.env

REACT_APP_MIXPANEL_ID && mixpanel.init(REACT_APP_MIXPANEL_ID)
REACT_APP_HOTJAR_ID && hotjar.initialize(parseInt(REACT_APP_HOTJAR_ID), 6, false)

const actions = {
  identify: (id: string) => {
    REACT_APP_MIXPANEL_ID && mixpanel.identify(id)
    REACT_APP_HOTJAR_ID && hotjar.identify(id, {})
  },
  alias: (id: string) => {
    REACT_APP_MIXPANEL_ID && mixpanel.alias(id)
  },
  track: (name: string, props?: Dict) => {
    REACT_APP_MIXPANEL_ID && mixpanel.track(name, props)
    REACT_APP_HOTJAR_ID && hotjar.event(name)
  },
  people: {
    set: (props: Dict) => {
      REACT_APP_MIXPANEL_ID && mixpanel.people.set(props)
    }
  }
}

export const Analytics = actions