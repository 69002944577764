import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Error from 'pages/Error'
import LibraryModule from 'pages/Library'
import LibraryAuthors from 'pages/Library/Authors'
import LibraryHome from 'pages/Library/Home'
import LibraryPillars from 'pages/Library/Pillars'
import LibrarySearch from 'pages/Library/Search'
import LibraryTags from 'pages/Library/Tags'
import Playbook from 'pages/Playbook'
import { KnownError } from 'types/Error.types'

export enum Path {
  AuthorLibrary = 'authors/:authorSlug',
  TagLibrary = 'tags/:tag',
  PillarLibrary = ':pillar',
  Playbook = ':pillar/:slug',
  Search = '/search',
  Root = '/'
}

// TODO: Lazy load page components
const AppRoutes = () => {
  return (
    <Routes>
      <Route path={Path.Root} element={<LibraryModule />}>
        <Route index={true} element={<LibraryHome />} />
        <Route path={Path.AuthorLibrary} element={<LibraryAuthors />} />
        <Route path={Path.PillarLibrary} element={<LibraryPillars />} />
        <Route path={Path.TagLibrary} element={<LibraryTags />} />
        <Route path={Path.Search} element={<LibrarySearch />} />
      </Route>
      <Route path={Path.Playbook} element={<Playbook />} />
      <Route path='*' element={<Error error={KnownError.NotFound} logoShown={true} />} />
    </Routes>
  )
}
export default AppRoutes
