import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LibraryPlaybook } from 'types/Library.types'

import AuthorLinks from 'components/AuthorLinks'
import TagLinks from 'components/TagLinks'

import './styles.css'

const LibraryCard: React.FC<LibraryPlaybook> = ({
  author,
  pillar,
  result,
  slug,
  tags,
  title
}) => {
  const navigate = useNavigate()

  const navigateToPlaybookPage = () => {
    navigate(`/${pillar}/${slug}`)
  }

  return (
    <div
      role='button'
      className='LibraryCard ___bgGradient'
      onClick={navigateToPlaybookPage}
    >
      <div className='LibraryCard_details'>
        <h2 className='LibraryCard_titleContainer'>
          <span className='LibraryCard_title'>{title}</span>
        </h2>
        <small className='LibraryCard_result'>{result}</small>
      </div>
      <div className='LibraryCard_links'>
        <div className='LibraryCard_tags'>
          <TagLinks disabled={true} tags={tags} />
        </div>
        {author && (
          <div className='LibraryCard_authors'>
            <AuthorLinks authors={author} />
          </div>
        )}
      </div>
    </div>
  )
}

export default LibraryCard