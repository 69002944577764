import React, { ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Footer, IconLegacy } from '@athena-labs/web-component-library'
import { Path } from 'AppRoutes'
import useQueryParams from 'hooks/useQueryParams'

import AppLogo from 'components/AppLogo'
import SupportModal from 'components/SupportModal'

import Package from '../../../package.json'

import './styles.css'


const Library: React.FC = () => {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const searchParam = queryParams.get('query')

  const [searchQuery, setSearchQuery] = useState<string>('')

  useEffect(() => {
    // TODO: Update the title for authors, tags, pillars and search
    // Set the title back to default
    document.title = 'Athena Playbook Library'
  }, [])

  useEffect(() => {
    searchParam && setSearchQuery(searchParam)
  }, [searchParam])

  const onSearchQueryChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearchQuery(value)
  }

  const onSearchQueryKeyUp: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter') {
      search()
    }
  }

  const search = () => {
    if (searchQuery) {
      navigate(`${Path.Search}?query=${encodeURIComponent(searchQuery)}`)
    }
  }

  const [supportModal, setSupportModal] = React.useState<boolean>(false)

  return (
    <section className='Library'>
      <nav className='Library_navigation ___bgGradient'>
        <aside className='Library_logo'>
          <AppLogo subtitle='Library' />
        </aside>
        <div className='Library_navigationContainer'>
          <div className='Library_search'>
            <div className='Library_searchInput'>
              <input
                className='Library_searchInputControl'
                type='text'
                name='search'
                placeholder='Search playbooks...'
                value={searchQuery || ''}
                onChange={onSearchQueryChange}
                onKeyUp={onSearchQueryKeyUp}
              />
              <div className='Library_searchIcon' onClick={search} >
                <IconLegacy type='search' color='secondary' />
              </div>
            </div>
          </div>

          {supportModal && (
            <SupportModal onClose={() => setSupportModal(false)} />
          )}
          <div className='Library_navigationSupport'>
            <button
              className='Library_navigationSupportButton'
              onClick={() => setSupportModal(true)}
            >
              <span className='block -mt-px hover:text-active-200'>?</span>
            </button>
          </div>
        </div>
      </nav>
      <div className='Library_content'>
        <Outlet />
      </div>
      <Footer version={`${Package.version}`} />
    </section>
  )
}

export default Library