import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Analytics } from 'adapters/Analytics'
import { createPlaybooksByTagQuery } from 'adapters/Contentful'
import { usePlaybookResults } from 'hooks/usePlaybookResults'
import ErrorPage from 'pages/Error'
import LibraryCard from 'pages/Library/components/Card'
import { TagLibraryRouteParams } from 'types/Route.types'

import LibraryLoadingSpinner from '../components/LibraryLoadingSpinner'

import './styles.css'

const TagLibrary: React.FC = () => {
  // We assert the Tag Library route params type to override react-router-dom's typing where route parameters can be undefined.
  // See here: https://stackoverflow.com/questions/59085911/required-url-param-on-react-router-v5-with-typescript-can-be-undefined#comment130119757_66697883
  const { tag } = useParams() as TagLibraryRouteParams
  const tagQuery = createPlaybooksByTagQuery(tag)
  const { playbookResults, error, isError } = usePlaybookResults(tagQuery)

  useEffect(() => {
    Analytics.track('Playbook tag', {
      tag
    })
  }, [])

  const tagName = useMemo(() => {
    if (!playbookResults) {
      return
    }
    const playbook = playbookResults[0]
    const tagResult = playbook.tags.find((t) => t.sys.id === tag)
    return tagResult?.name
  }, [playbookResults])

  if (isError) {
    return <ErrorPage error={error} />
  }

  if (!playbookResults) {
    return <LibraryLoadingSpinner />
  }

  return (
    <section className='TagLibrary'>
      <div className='TagLibrary_content ___content'>
        <div className='___section'>
          <div className='___sectionHeading'>
            <h2>
              {tagName}
            </h2>
          </div>
        </div>
        <div className='___section'>
          <div className='___gridWrapper'>
            <div className='___grid'>
              {playbookResults && playbookResults.map((playbook) => (
                <LibraryCard key={playbook.title} {...playbook} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TagLibrary