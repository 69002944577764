import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Logo } from '@athena-labs/web-component-library'
import { Path } from 'AppRoutes'
import { AppLogoProps } from 'types/App.types'

import './styles.css'

const AppLogo: React.FC<AppLogoProps> = ({ subtitle }) => {
  const navigate = useNavigate()
  return (
    <figure className='AppLogo'>
      <div
        className='AppLogo_imageContainer'
        onClick={() => {
          window.open('https://www.athena.com', '_self')
        }}>
        <Logo type='athena' color='white' />
      </div>
      <div className='AppLogo_divider' />
      <div
        className='AppLogo_titleContainer'
        onClick={() => {
          navigate(Path.Root)
        }}>
        <p className='AppLogo_title'>Playbook</p>
        {subtitle && (
          <span className='AppLogo_subtitle'>{subtitle}</span>
        )}
        <span className='AppLogo_releaseLabel'>Beta</span>
      </div>
    </figure>
  )
}

export default AppLogo
