import React from 'react'
import { Button } from '@athena-labs/web-component-library'
import { toast } from '@athena-labs/web-component-library'
import { Analytics } from 'adapters/Analytics'
import { ShareModalProps, ShareTarget } from 'types/Share.types'

import Modal from 'components/Modal'

import ShareButton from './components/ShareButton'

import './styles.css'

const ShareModal: React.FC<ShareModalProps> = ({ shareData, trackedData, onClose }) => {
  const copyUrl = async () => {
    try {
      await navigator.clipboard.writeText(location.href)
      Analytics.track('Playbook URL copied', trackedData)
      toast('URL has been copied', 'default')
    } catch (exception) {
      toast('Cannot copy URL', 'error')
    }
  }

  const shareProps = {
    shareData,
    trackedData
  }

  return (
    <Modal
      heading='Share this playbook:'
      body={(
        <div className='SharePlaybook'>
          <div className='SharePlaybook_ButtonLinks'>
            <ShareButton target={ShareTarget.Email} {...shareProps} />
            <ShareButton target={ShareTarget.LinkedIn} {...shareProps} />
            <ShareButton target={ShareTarget.Twitter} {...shareProps} />
          </div>
          <div className='SharePlaybook_URLContainer'>
            <input
              id='playbookUrl'
              name='playbookUrl'
              className='SharePlaybook_inputControl'
              type='text'
              value={location.href}
              readOnly={true}
            />
            <Button
              icon='link'
              variant='rounded'
              onClick={copyUrl}
            >
              Copy URL
            </Button>
          </div>
        </div>
      )}
      onClose={onClose} />)
}

export default ShareModal
