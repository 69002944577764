import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { IconLegacy, useMediaQuery } from '@athena-labs/web-component-library'
import { AnimatePresence, motion } from 'framer-motion'
import { useHasOverflowingContent } from 'hooks/useHasOverflowingContent'
import { LibraryHomeSectionProps } from 'types/Library.types'
import { PlaybookPillar, PlaybookProps } from 'types/Playbook.types'

import LibraryHomeCard from '../Card'
import LibraryHomePreviewCard from '../PreviewCard'

import './styles.css'

const LibraryHomeSection: React.FC<LibraryHomeSectionProps> = ({
  featured = false,
  icon,
  playbooks,
  title
}) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const hasOverflowingContent = useHasOverflowingContent(contentWrapperRef, 'horizontal')

  const { isDesktop } = useMediaQuery()

  // Note: These widths are in px
  const cardSizeWidth = (isDesktop ? 340 : 220) + 12 // Adds the 6px margin on each side.
  const PAGE_LEFT_GUTTER_SIZE = 56

  const [
    hoveredPlaybookCard,
    setHoveredPlaybookCard
  ] = useState<PlaybookProps | undefined>(undefined)

  const [scrolledToEnd, setScrolledToEnd] = useState<boolean>(false)
  const [contentScrollLeft, setContentScrollLeft] = useState<number>(0)
  const [previewCardLeftPosition, setPreviewCardLeftPosition] = useState<string>('0')

  const showPreviewCard = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    playbook: PlaybookProps
  ) => {
    const containerWidth = contentWrapperRef.current?.offsetWidth ?? 0
    const peekingCardWidth = containerWidth % cardSizeWidth
    const cardPosition = getCardPosition({
      cursorXPosition: event.clientX
    })

    // Prevent showing the preview card when hovering over the peeking card so that it does not block the scroll buttons.
    const hasPeekingCard = peekingCardWidth !== 0
    const isPeekingCardHovered = checkIsPeekingCardHovered({
      cardOrdinalPosition: cardPosition + 1 // We do plus 1 since the sanitizedCardPosition is zero-indexed.
    })
    if (hasPeekingCard && isPeekingCardHovered) {
      return
    }

    // Get the endScrollOffset by calculating the width of the hidden area of the peeking card.
    const endScrollOffset = cardSizeWidth - peekingCardWidth
    const cardPositionValue = scrolledToEnd ?
      cardPosition * cardSizeWidth - endScrollOffset :
      cardPosition * cardSizeWidth

    setPreviewCardLeftPosition(`${cardPositionValue}px`)
    setHoveredPlaybookCard(playbook)
  }

  const onContentWrapperScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    if (contentWrapperRef.current) {
      setContentScrollLeft(contentWrapperRef.current.scrollLeft)
    }
    checkIsScrolledToEnd(event)
  }

  const getCardPosition = ({ cursorXPosition }: {
    cursorXPosition: number
  }) => {
    const containerWidth = contentWrapperRef.current?.offsetWidth ?? 0
    const peekingCardWidth = containerWidth % cardSizeWidth
    const endScrollOffset = cardSizeWidth - peekingCardWidth

    const sanitizedXPosition = scrolledToEnd ?
      cursorXPosition - PAGE_LEFT_GUTTER_SIZE + endScrollOffset :
      cursorXPosition - PAGE_LEFT_GUTTER_SIZE

    const hoveredCardPosition = Math.floor(
      (sanitizedXPosition) / cardSizeWidth
    )

    return Math.max(0, hoveredCardPosition)
  }

  const checkIsPeekingCardHovered = ({ cardOrdinalPosition }: {
    cardOrdinalPosition: number
  }) => {
    const containerWidth = contentWrapperRef.current?.offsetWidth ?? 0
    const wholeCardsInViewCount = Math.floor(containerWidth / cardSizeWidth)

    return scrolledToEnd ?
      cardOrdinalPosition === 1 :
      cardOrdinalPosition > wholeCardsInViewCount
  }

  const checkIsScrolledToEnd = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const containerWidth = contentWrapperRef.current?.offsetWidth ?? 0
    const scrollWidth = contentWrapperRef.current?.scrollWidth ?? 0
    const leftScrollOffset = event.currentTarget.scrollLeft
    const isScrolledToEnd = Math.round(scrollWidth - leftScrollOffset) === containerWidth
    setScrolledToEnd(isScrolledToEnd)
  }

  const scrollPreviousCard = () => {
    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollLeft -= cardSizeWidth
    }
  }

  const scrollNextCard = () => {
    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollLeft += cardSizeWidth
    }
  }

  const getPillarLink = (pillarName: string) => {
    if (Object.values<string>(PlaybookPillar).includes(pillarName)) {
      return `/${pillarName}`
    }
    return undefined
  }

  const pillarLink = getPillarLink(title)

  return (
    <div
      className={[
        'LibraryHomeSection',
        featured && 'LibraryHomeSection___featured'
      ].join(' ')}>
      <div className='LibraryHomeSection_heading'>
        {icon && (
          <div className='LibraryHomeSection_icon'>
            <IconLegacy type={`${icon}`} color='secondary' />
          </div>
        )}
        <h2 className='LibraryHomeSection_headingTitle'>
          {title}
        </h2>
        {pillarLink && (
          <Link
            className='LibraryHomeSection_pillarLink'
            to={pillarLink}>
            See all &gt;
          </Link>
        )}

      </div>
      <div className='LibraryHomeSection_content'>
        <AnimatePresence>
          {contentScrollLeft !== 0 && (
            <motion.button
              className='LibraryHomeSection_scrollNavigation LibraryHomeSection_scrollNavigation___left'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={scrollPreviousCard}>
              <IconLegacy type='chevron-left' color='secondary' />
            </motion.button>
          )}
          {isDesktop && hoveredPlaybookCard && (
            <motion.div
              key='LibraryHomeSection_previewCard'
              className='LibraryHomeSection_previewCard'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                left: previewCardLeftPosition
              }}>
              <LibraryHomePreviewCard
                playbook={hoveredPlaybookCard}
                onMouseLeave={() => setHoveredPlaybookCard(undefined)} />
            </motion.div>
          )}
        </AnimatePresence>
        <div
          className='LibraryHomeSection_contentWrapper'
          ref={contentWrapperRef}
          onScroll={onContentWrapperScroll}
        >
          {playbooks.map((playbook) => (
            <LibraryHomeCard
              key={playbook.title}
              playbook={playbook}
              onMouseEnter={(event) => showPreviewCard(event, playbook)} />
          ))}
        </div>
        <AnimatePresence>
          {hasOverflowingContent && !scrolledToEnd && (
            <motion.button
              className='LibraryHomeSection_scrollNavigation LibraryHomeSection_scrollNavigation___right'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={scrollNextCard}>
              <IconLegacy type='chevron-right' color='secondary' />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

LibraryHomeSection.defaultProps = {
  featured: false
}

export default LibraryHomeSection