import React, { useEffect, useRef, useState } from 'react'

import './styles.css'

const PlaybookContentHeader: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null)

  const [headerTop, setHeaderTop] = useState<number | undefined>(undefined)
  const [isSticky, setIsSticky] = useState<boolean>(false)


  useEffect(() => {
    if (headerRef.current === null) {
      return
    }

    const headerEl = headerRef.current.getBoundingClientRect()
    setHeaderTop(headerEl.top)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }), [headerTop]

  const onScroll = () => {
    if (headerTop === undefined) {
      return
    }

    const scrollTop = window.scrollY
    setIsSticky(scrollTop >= headerTop - 16)
  }


  return (
    <div
      ref={headerRef}
      className={[
        'PlaybookContentHeader',
        isSticky && 'PlaybookContentHeader___sticky'
      ].join(' ')}>
      <div className='PlaybookContentHeader_item PlaybookContentHeader_item___client'>
        <div className='PlaybookContentHeader_itemIcon'></div>
        <span>You</span>
      </div>
      <div className='PlaybookContentHeader_item PlaybookContentHeader_item___ea'>
        <div className='PlaybookContentHeader_itemIcon'></div>
        <span>Your EA</span>
      </div>
    </div>
  )
}
export default PlaybookContentHeader